<template>
  <drag
    :disabled="!authorization.update"
    :class="'itemsource' + column.item._id"
    :id="'itemsource' + source._id"
    :data-id="source._id"
    :data-column="column.item._id"
    data-type="item"
    :transfer-data="{ source }"
    :key="'drag' + source._id + source._rev"
  >
    <v-card
      elevation="1"
      class="dragicon ma-1 pa-1 list-card"
      outlined
      :class="allowDrag ? 'cursor-move' : ''"
      :id="source._id"
    >
      <v-list-item three-line>
        <v-list-item-content class="pb-0">
          <v-list-item-title
            class="subtitle-2 mb-1 boardtitle"
            @click="openTask(source)"
            >{{ source.title }}</v-list-item-title
          >
          <v-list-item-subtitle class="mark-sub">
            <markdown-it-vue
              class="md-body"
              :content="source.body"
              :collection="collection"
            />
          </v-list-item-subtitle>
          <div class="overline mt-0" v-if="source.updatedAt">
            {{ utils.formatDateTime(source.updatedAt, "DD/MMM/YYYY hh:mm A") }}
          </div>
        </v-list-item-content>
        <v-list-item-avatar
          color="orange accent-1"
          size="30"
          :title="userInfo.display"
          v-show="source.assignee"
        >
          <AsyncAvatarImage
            :username="source.assignee"
            :collection="collection"
          />
        </v-list-item-avatar>
      </v-list-item>
      <div class="bottom-widget mb-2" v-show="comments || attachments">
        <v-list-item-action class="mx-1 my-0" v-show="comments">
          <div>
            <v-icon class="small-icon primary--text"
              >mdi-comment-text-outline</v-icon
            ><span class="small-badge">{{ comments }}</span>
          </div>
        </v-list-item-action>
        <v-list-item-action class="mx-1 my-0" v-show="attachments">
          <div>
            <v-icon class="small-icon primary--text">mdi-attachment</v-icon
            ><span class="small-badge">{{ attachments }}</span>
          </div>
        </v-list-item-action>
      </div>
      <DragDropFile
        v-if="!isMobile"
        v-show="!disabledrop"
        :disabledrop="disabledrop"
        :item="source"
        :editor="editor"
        :authorization="authorization"
        @dropText="dropText"
      >
        <div class="dragarea" @click="openTask(source)"></div>
      </DragDropFile>
    </v-card>
  </drag>
</template>
<script>
import Utility from '@/components/common/utils.js'
import { View } from '@/collections'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'BoardCard',
  components: {
    DragDropFile: () => import('@/components/fileagent/DragDropFile.vue'),
    AsyncAvatarImage: () => import('@/components/AsyncAvatarImage.vue')
  },
  data: function () {
    return {
      utils: Utility,
      userInfo: {},
      commentView: null,
      attachmentView: null
    }
  },
  props: {
    editor: { type: Object },
    disabledrop: {
      type: Boolean,
      default: false
    },
    authorization: Object,
    collectionID: String,
    account: String,
    collection: Object,
    perspective: String,
    allowDrag: {
      type: Boolean,
      default: true
    },
    source: {
      type: Object,
      default () {
        return {}
      }
    },
    column: Object
  },
  beforeDestroy () {
    if (this.commentView !== null) this.commentView.close()
    if (this.attachmentView !== null) this.attachmentView.close()
  },
  async created () {
    this.commentView = await new View(this.collection).open({
      of: { container: this.source._id, type: 'comment' }
    })
    this.attachmentView = await new View(this.collection).open({
      of: { attached_to: this.source._id }
    })
  },
  async mounted () {
    this.userInfo = await this.utils.getUserDetails(
      this.collection,
      this.source.assignee
    )
  },
  computed: {
    isMobile () {
      return !!(
        this.$vuetify.breakpoint.name === 'sm' ||
        this.$vuetify.breakpoint.name === 'xs'
      )
    },
    comments () {
      return this.commentView !== null ? this.commentView.items.length : 0
    },
    attachments () {
      return this.attachmentView !== null
        ? this.attachmentView.items.length
        : 0
    }
  },
  methods: {
    openTask (source) {
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: source._id
        }
      })
    },
    async dropText (text) {
      if (this.utils.validateUrl(text)) {
        var view = await new View(this.collection).open({
          of: { attached_to: this.source._id }
        })
        await _attachment.putUrl(view, this.source, text, this)
        await view.close()
      }
    },
    dragStart (e) {
      console.debug('drag start', e)
      this.disabledropitem = true
      this.$emit('update:disabledrop', this.disabledropitem)
    },
    dragEnd (e) {
      this.disabledropitem = false
      this.$emit('update:disabledrop', this.disabledropitem)
      document.body.click()
    }
  }
}
</script>
<style scoped>
.boardtitle {
  color: #1e88e5;
  /* cursor: pointer; */
}
.boardtitle:hover {
  text-decoration: underline;
}
.cursor-move {
  /* cursor: move; */
}
.sortable-chosen .is-drag-valid.is-drag-over {
  background: none;
  display: none;
}
.dragarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  cursor: pointer;
}
.is-drag-valid.is-drag-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.small-badge {
  font-size: 0.9375rem;
}
.small-icon {
  font-size: 20px !important;
  margin-right: 3px;
}
.bottom-widget {
  margin-left: 10px;
}
.mark-sub.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle{
  -webkit-line-clamp:3 !important;
   height:3.1em;
}
</style>
